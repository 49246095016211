/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextField,
  TextArea,
  SelectField,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Row, Col } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import React from 'react';
import * as Yup from 'yup';
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import DatePickerWithFormik from './DatePickerWithFormik';
import { Field, ErrorMessage } from 'formik';

export default function InvestorForm({ location, ...rest }) {
  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return <Alert variant="success">Danke für Ihre Nachricht!</Alert>;
  }
  return (
    <ControlledForm
      name="investor-werden"
      initialValues={{
        vorname: '',
        nachname: '',
        telefon: '',
        email: '',
        investmentVolumen: '',
        nachricht: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        // console.log(values)
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <Row>
        <Col>
          <TextField label="Vorname" name="vorname" required />
        </Col>
        <Col>
          <TextField label="Nachname" name="nachname" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField label="Telefon" name="telefon" required />
        </Col>
        <Col>
          <TextField label="E-Mail" name="email" required required />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectField
            label="Ich interessiere mich für Projekte mit folgendem Investmentvolumen:"
            name="investmentVolumen"
            required
          >
            <option></option>
            <option>Ab 50.000,- EUR</option>
            <option>Ab 250.000,- EUR</option>
            <option>Ab 1.000.000,- EUR</option>
          </SelectField>
        </Col>
      </Row>

      <TextArea
        label="Folgende Informationen möchte ich vorab bekannt geben:"
        name="nachricht"
        rows="5"
      />

      <SubmitButton>
        Jetzt Anfrage absenden <i class="fal fa-arrow-right"></i>
      </SubmitButton>
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  nachname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  email: Yup.string()
    .trim()
    .email('Bitte eine gültige E-Mail-Adresse eingeben')
    .required('Dieses Feld ist verpflichtend'),
});
