/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import ListSection from '../../../components/ListSection';
import InvestorForm from '../../../components/Forms/InvestorForm';
import Link from '@fify.at/gatsby-theme-base/src/components/Link/Link';

export default function InvestorWerden({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Werden Sie jetzt Investor!"
      description="WirkaufendeinHaus.at geht in punkto Investments völlig neue Wege: schon ab 50.000,- EUR einsteigen und hohe Erträge erzielen."
      image={data.hero.childImageSharp.fluid}
      keywords={['Investor', 'werden']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Werden Sie jetzt Investor!"
        subtitle="Eigenheime zählen zu den sichersten Immobilieninvestments."
        image={data.hero}
      />

      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>
            Warum Sie in WirkaufendeinHaus.at investieren sollten
          </Heading>
        </div>
        <p>
          Aktuell zählen Investitionen in Eigenheime zu den sichersten
          Immobilieninvestments. Der Markt gestaltet sich riesig und
          üblicherweise auch sehr liquid. Die Anzahl der Interessenten
          übersteigt derzeit das Angebot an Immobilien.
        </p>
        <p>
          Dennoch agieren Investoren nach wie vor in diesem Bereich noch sehr
          verhalten. Oft fehlt es an der notwendigen Expertise und Sicherheit am
          Markt.
        </p>
        <p>
          WirkaufendeinHaus.at geht in punkto Investments völlig neue Wege. Als
          einer der ersten Marktteilnehmer in diesem noch jungen und
          unverbrauchten Markt suchen wir gezielt nach ungeschliffenen Diamanten
          und bieten unseren Investoren fair bewertete Immobilien, die wir durch
          unser professionelles Netzwerk mit viel Know How und Erfahrung für sie
          in attraktive Objekte verwandeln.
        </p>
        <p>
          WirkaufendeinHaus.at strebt das Ziel an, ein großes, breit gestreutes
          Immobilien-Portfolio aufzubauen, das Investoren hohe Erträge
          garantiert. Dabei setzen wir voraus, dass das Verhältnis von
          Investition und Ertrag stimmt.
        </p>
        <h3>Schon ab EUR 50.000,- einsteigen und hohen Ertrag erzielen</h3>
        <p>
          Unser Ansatzpunkt ist es, in großer Zahl für unsere Investoren
          Einfamilienhäuser zu kaufen, deren Wert durch Auffrischung zu steigern
          und in möglichst kurzer Zeit gewinnbringend weiter zu verkaufen.
        </p>
        <p>
          Erfahrene, verlässliche Profis mit Handschlagqualität gewährleisten
          dabei eine komplette, sichere Abwicklung beginnend bei der Suche, über
          die Auswahl, den Ankauf, die Entwicklung und Pflege der Immobilie bis
          letztendlich zum erfolgreichen Weiterverkauf.
        </p>

        <p>
          Je nach Investitionssumme sind unsere Anleger in mehreren Objekten
          direkt investiert, genießen außerordentlich hohe Sicherheit und
          Transparenz durch einen direkten Ansprechpartner, der sich vor Ort
          professionell um alles kümmert, sowie einen vergleichsweise hohen
          Ertrag durch den damit verbundenen ständigen An- und Verkauf.
        </p>

        <p>
          So ist es für uns möglich, auch größere Volumen in diesem
          normalerweise klein-gliedrigen Markt unterzubringen.
        </p>
      </Section>

      <ListSection
        background="muted"
        title="Unsere Vorteile im Überblick"
        text={[
          {
            listItem: 'Stabiler Eigenheimmarkt',
          },
          {
            listItem: 'Grundbücherliche Sicherstellung',
          },
          {
            listItem: 'Jahrelanges Knowhow und Expertise verlässlicher Profis',
          },
          {
            listItem:
              'Sicherheit und Transparenz über Kosten, Gebühren, Entwicklung, Strategie und Risikoprofil',
          },
          {
            listItem:
              'Möglichkeit auf hohe Erträge durch hohe Schlagzahl der Ankäufe/Verkäufe pro Jahr',
          },
          {
            listItem:
              'Kurze Projektlaufzeiten (durchschnittliche Projektlaufzeit ca. 6 Monate)',
          },
          {
            listItem: 'Risikostreuung durch gezieltes Investment Sharing',
          },
          {
            listItem: 'Individuelle Lösungen jederzeit möglich',
          },
        ]}
      />

      <Section container="small" sx={{ textAlign: 'center' }}>
        <Heading>So werden Sie Investor!</Heading>
        <p>
          Profitieren auch Sie von unserem Know-how und führen Sie Ihr Kapital
          zum Erfolg!
        </p>
        <p>
          Sind Sie interessiert, dann freuen wir uns über Ihre Kontaktaufnahme
          und informieren Sie gerne in einem Erstgespräch über Ihre Investment -
          Möglichkeiten.
        </p>
      </Section>

      <Section
        sx={{
          maxWidth: [null, null, 500, 760, 1100],
          marginX: 'auto',
          marginBottom: 7,
          boxShadow: `0 0 30px rgba(0,0,0,0.1)`,
          backgroundColor: '#fff',
        }}
      >
        <Heading
          sx={{ color: 'primary', fontWeight: 'bold', textAlign: 'center' }}
        >
          Werden Sie jetzt Investor bei WirkaufendeinHaus.at und erzielen Sie in
          kurzer Zeit bereits hohe Erträge!
        </Heading>
        <p>
          Ihr Investment ist nur einen Klick entfernt. Bitte füllen Sie die
          nachfolgenden Felder aus und senden Sie anschließend Ihre Anfrage an
          uns.
        </p>
        <p>
          Unsere Experten melden sich in Kürze zur Vereinbarung eines
          persönlichen Gesprächstermins.
        </p>
        <InvestorForm location={location} />
        <p sx={{ '&&&': { marginTop: 4 } }}>
          <small>
            * <Link to="/datenschutzerklaerung/">Datenschutz</Link> ist uns
            besonders wichtig! Seien Sie versichert, dass wir Ihre Daten stets
            diskret und sicher verarbeiten.
          </small>
        </p>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-investor-werden" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
